html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #485fc7;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #363636;
}

code {
  color: #da1039;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  height: 2px;
  background-color: #f5f5f5;
  border: none;
  margin: 1.5rem 0;
  display: block;
}

img {
  height: auto;
  max-width: 100%;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #4a4a4a;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: #363636;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.textarea, .input {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  height: 2.5em;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: calc(.5em - 1px) calc(.75em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.textarea:focus, .input:focus, .is-focused.textarea, .is-focused.input, .textarea:active, .input:active, .is-active.textarea, .is-active.input {
  outline: none;
}

[disabled].textarea, [disabled].input, fieldset[disabled] .textarea, fieldset[disabled] .input {
  cursor: not-allowed;
}

.is-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.table-container:not(:last-child), .table:not(:last-child), .title:not(:last-child), .subtitle:not(:last-child) {
  margin-bottom: 1.5rem;
}

.control.is-loading:after {
  content: "";
  height: 1em;
  width: 1em;
  border: 2px solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.hero-video, .is-overlay {
  position: absolute;
  inset: 0;
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black {
  color: #0a0a0a !important;
}

a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #0a0a0a !important;
}

.has-text-light {
  color: #f5f5f5 !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important;
}

.has-background-light {
  background-color: #f5f5f5 !important;
}

.has-text-dark {
  color: #363636 !important;
}

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #1c1c1c !important;
}

.has-background-dark {
  background-color: #363636 !important;
}

.has-text-primary {
  color: #00d1b2 !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #009e86 !important;
}

.has-background-primary {
  background-color: #00d1b2 !important;
}

.has-text-primary-light {
  color: #ebfffc !important;
}

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #b8fff4 !important;
}

.has-background-primary-light {
  background-color: #ebfffc !important;
}

.has-text-primary-dark {
  color: #00947e !important;
}

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: #00c7a9 !important;
}

.has-background-primary-dark {
  background-color: #00947e !important;
}

.has-text-link {
  color: #485fc7 !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #3449a8 !important;
}

.has-background-link {
  background-color: #485fc7 !important;
}

.has-text-link-light {
  color: #eff1fa !important;
}

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #c8cfee !important;
}

.has-background-link-light {
  background-color: #eff1fa !important;
}

.has-text-link-dark {
  color: #3850b7 !important;
}

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #576dcb !important;
}

.has-background-link-dark {
  background-color: #3850b7 !important;
}

.has-text-info {
  color: #3e8ed0 !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #2b74b1 !important;
}

.has-background-info {
  background-color: #3e8ed0 !important;
}

.has-text-info-light {
  color: #eff5fb !important;
}

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #c6ddf1 !important;
}

.has-background-info-light {
  background-color: #eff5fb !important;
}

.has-text-info-dark {
  color: #296fa8 !important;
}

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #368ace !important;
}

.has-background-info-dark {
  background-color: #296fa8 !important;
}

.has-text-success {
  color: #48c78e !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #34a873 !important;
}

.has-background-success {
  background-color: #48c78e !important;
}

.has-text-success-light {
  color: #effaf5 !important;
}

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #c8eedd !important;
}

.has-background-success-light {
  background-color: #effaf5 !important;
}

.has-text-success-dark {
  color: #257953 !important;
}

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #31a06e !important;
}

.has-background-success-dark {
  background-color: #257953 !important;
}

.has-text-warning {
  color: #ffe08a !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important;
}

.has-background-warning {
  background-color: #ffe08a !important;
}

.has-text-warning-light {
  color: #fffaeb !important;
}

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important;
}

.has-background-warning-light {
  background-color: #fffaeb !important;
}

.has-text-warning-dark {
  color: #946c00 !important;
}

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important;
}

.has-background-warning-dark {
  background-color: #946c00 !important;
}

.has-text-danger {
  color: #f14668 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #ee1742 !important;
}

.has-background-danger {
  background-color: #f14668 !important;
}

.has-text-danger-light {
  color: #feecf0 !important;
}

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #fabdc9 !important;
}

.has-background-danger-light {
  background-color: #feecf0 !important;
}

.has-text-danger-dark {
  color: #cc0f35 !important;
}

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #ee2049 !important;
}

.has-background-danger-dark {
  background-color: #cc0f35 !important;
}

.has-text-black-bis {
  color: #121212 !important;
}

.has-background-black-bis {
  background-color: #121212 !important;
}

.has-text-black-ter {
  color: #242424 !important;
}

.has-background-black-ter {
  background-color: #242424 !important;
}

.has-text-grey-darker {
  color: #363636 !important;
}

.has-background-grey-darker {
  background-color: #363636 !important;
}

.has-text-grey-dark {
  color: #4a4a4a !important;
}

.has-background-grey-dark {
  background-color: #4a4a4a !important;
}

.has-text-grey {
  color: #7a7a7a !important;
}

.has-background-grey {
  background-color: #7a7a7a !important;
}

.has-text-grey-light {
  color: #b5b5b5 !important;
}

.has-background-grey-light {
  background-color: #b5b5b5 !important;
}

.has-text-grey-lighter {
  color: #dbdbdb !important;
}

.has-background-grey-lighter {
  background-color: #dbdbdb !important;
}

.has-text-white-ter {
  color: #f5f5f5 !important;
}

.has-background-white-ter {
  background-color: #f5f5f5 !important;
}

.has-text-white-bis {
  color: #fafafa !important;
}

.has-background-white-bis {
  background-color: #fafafa !important;
}

.is-flex-direction-row {
  flex-direction: row !important;
}

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.is-flex-direction-column {
  flex-direction: column !important;
}

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.is-justify-content-flex-start {
  justify-content: flex-start !important;
}

.is-justify-content-flex-end {
  justify-content: flex-end !important;
}

.is-justify-content-center {
  justify-content: center !important;
}

.is-justify-content-space-between {
  justify-content: space-between !important;
}

.is-justify-content-space-around {
  justify-content: space-around !important;
}

.is-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.is-justify-content-start {
  justify-content: start !important;
}

.is-justify-content-end {
  justify-content: end !important;
}

.is-justify-content-left {
  justify-content: left !important;
}

.is-justify-content-right {
  justify-content: right !important;
}

.is-align-content-flex-start {
  align-content: flex-start !important;
}

.is-align-content-flex-end {
  align-content: flex-end !important;
}

.is-align-content-center {
  align-content: center !important;
}

.is-align-content-space-between {
  align-content: space-between !important;
}

.is-align-content-space-around {
  align-content: space-around !important;
}

.is-align-content-space-evenly {
  align-content: space-evenly !important;
}

.is-align-content-stretch {
  align-content: stretch !important;
}

.is-align-content-start {
  align-content: start !important;
}

.is-align-content-end {
  align-content: end !important;
}

.is-align-content-baseline {
  align-content: baseline !important;
}

.is-align-items-stretch {
  align-items: stretch !important;
}

.is-align-items-flex-start {
  align-items: flex-start !important;
}

.is-align-items-flex-end {
  align-items: flex-end !important;
}

.is-align-items-center {
  align-items: center !important;
}

.is-align-items-baseline {
  align-items: baseline !important;
}

.is-align-items-start {
  align-items: start !important;
}

.is-align-items-end {
  align-items: end !important;
}

.is-align-items-self-start {
  align-items: self-start !important;
}

.is-align-items-self-end {
  align-items: self-end !important;
}

.is-align-self-auto {
  align-self: auto !important;
}

.is-align-self-flex-start {
  align-self: flex-start !important;
}

.is-align-self-flex-end {
  align-self: flex-end !important;
}

.is-align-self-center {
  align-self: center !important;
}

.is-align-self-baseline {
  align-self: baseline !important;
}

.is-align-self-stretch {
  align-self: stretch !important;
}

.is-flex-grow-0 {
  flex-grow: 0 !important;
}

.is-flex-grow-1 {
  flex-grow: 1 !important;
}

.is-flex-grow-2 {
  flex-grow: 2 !important;
}

.is-flex-grow-3 {
  flex-grow: 3 !important;
}

.is-flex-grow-4 {
  flex-grow: 4 !important;
}

.is-flex-grow-5 {
  flex-grow: 5 !important;
}

.is-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.is-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.is-flex-shrink-2 {
  flex-shrink: 2 !important;
}

.is-flex-shrink-3 {
  flex-shrink: 3 !important;
}

.is-flex-shrink-4 {
  flex-shrink: 4 !important;
}

.is-flex-shrink-5 {
  flex-shrink: 5 !important;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-clickable {
  cursor: pointer !important;
  pointer-events: all !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-relative {
  position: relative !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.pt-3 {
  padding-top: .75rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pl-3 {
  padding-left: .75rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5 {
  padding-top: 1.5rem !important;
}

.pr-5 {
  padding-right: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-auto {
  padding: auto !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

.is-size-1 {
  font-size: 3rem !important;
}

.is-size-2 {
  font-size: 2.5rem !important;
}

.is-size-3 {
  font-size: 2rem !important;
}

.is-size-4 {
  font-size: 1.5rem !important;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .75rem !important;
}

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 3rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.5rem !important;
  }

  .is-size-3-mobile {
    font-size: 2rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.25rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 3rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.5rem !important;
  }

  .is-size-3-tablet {
    font-size: 2rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.25rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .75rem !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 3rem !important;
  }

  .is-size-2-touch {
    font-size: 2.5rem !important;
  }

  .is-size-3-touch {
    font-size: 2rem !important;
  }

  .is-size-4-touch {
    font-size: 1.5rem !important;
  }

  .is-size-5-touch {
    font-size: 1.25rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 3rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.5rem !important;
  }

  .is-size-3-desktop {
    font-size: 2rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.25rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 3rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.5rem !important;
  }

  .is-size-3-widescreen {
    font-size: 2rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.25rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .75rem !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 3rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.5rem !important;
  }

  .is-size-3-fullhd {
    font-size: 2rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.25rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .75rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (max-width: 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (max-width: 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (max-width: 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  height: .01em !important;
  white-space: nowrap !important;
  width: .01em !important;
  border: none !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible {
  visibility: hidden !important;
}

@media screen and (max-width: 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.hero .navbar {
  background: none;
}

.hero .tabs ul {
  border-bottom: none;
}

.hero.is-white {
  color: #0a0a0a;
  background-color: #fff;
}

.hero.is-white a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-white strong {
  color: inherit;
}

.hero.is-white .title {
  color: #0a0a0a;
}

.hero.is-white .subtitle {
  color: #0a0a0ae6;
}

.hero.is-white .subtitle a:not(.button), .hero.is-white .subtitle strong {
  color: #0a0a0a;
}

@media screen and (max-width: 1023px) {
  .hero.is-white .navbar-menu {
    background-color: #fff;
  }
}

.hero.is-white .navbar-item, .hero.is-white .navbar-link {
  color: #0a0a0ab3;
}

.hero.is-white a.navbar-item:hover, .hero.is-white a.navbar-item.is-active, .hero.is-white .navbar-link:hover, .hero.is-white .navbar-link.is-active {
  color: #0a0a0a;
  background-color: #f2f2f2;
}

.hero.is-white .tabs a {
  color: #0a0a0a;
  opacity: .9;
}

.hero.is-white .tabs a:hover {
  opacity: 1;
}

.hero.is-white .tabs li.is-active a {
  opacity: 1;
  color: #fff !important;
}

.hero.is-white .tabs.is-boxed a, .hero.is-white .tabs.is-toggle a {
  color: #0a0a0a;
}

.hero.is-white .tabs.is-boxed a:hover, .hero.is-white .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-white .tabs.is-boxed li.is-active a, .hero.is-white .tabs.is-boxed li.is-active a:hover, .hero.is-white .tabs.is-toggle li.is-active a, .hero.is-white .tabs.is-toggle li.is-active a:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.hero.is-white.is-bold {
  background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-white.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #e8e3e4 0%, #fff 71% 100%);
  }
}

.hero.is-black {
  color: #fff;
  background-color: #0a0a0a;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-black strong {
  color: inherit;
}

.hero.is-black .title {
  color: #fff;
}

.hero.is-black .subtitle {
  color: #ffffffe6;
}

.hero.is-black .subtitle a:not(.button), .hero.is-black .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-black .navbar-menu {
    background-color: #0a0a0a;
  }
}

.hero.is-black .navbar-item, .hero.is-black .navbar-link {
  color: #ffffffb3;
}

.hero.is-black a.navbar-item:hover, .hero.is-black a.navbar-item.is-active, .hero.is-black .navbar-link:hover, .hero.is-black .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.hero.is-black .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-black .tabs a:hover {
  opacity: 1;
}

.hero.is-black .tabs li.is-active a {
  opacity: 1;
  color: #0a0a0a !important;
}

.hero.is-black .tabs.is-boxed a, .hero.is-black .tabs.is-toggle a {
  color: #fff;
}

.hero.is-black .tabs.is-boxed a:hover, .hero.is-black .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-black .tabs.is-boxed li.is-active a, .hero.is-black .tabs.is-boxed li.is-active a:hover, .hero.is-black .tabs.is-toggle li.is-active a, .hero.is-black .tabs.is-toggle li.is-active a:hover {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-black.is-bold {
  background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-black.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #000 0%, #0a0a0a 71%, #181616 100%);
  }
}

.hero.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
}

.hero.is-light a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-light strong {
  color: inherit;
}

.hero.is-light .title {
  color: #000000b3;
}

.hero.is-light .subtitle {
  color: #000000e6;
}

.hero.is-light .subtitle a:not(.button), .hero.is-light .subtitle strong {
  color: #000000b3;
}

@media screen and (max-width: 1023px) {
  .hero.is-light .navbar-menu {
    background-color: #f5f5f5;
  }
}

.hero.is-light .navbar-item, .hero.is-light .navbar-link {
  color: #000000b3;
}

.hero.is-light a.navbar-item:hover, .hero.is-light a.navbar-item.is-active, .hero.is-light .navbar-link:hover, .hero.is-light .navbar-link.is-active {
  color: #000000b3;
  background-color: #e8e8e8;
}

.hero.is-light .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-light .tabs a:hover {
  opacity: 1;
}

.hero.is-light .tabs li.is-active a {
  opacity: 1;
  color: #f5f5f5 !important;
}

.hero.is-light .tabs.is-boxed a, .hero.is-light .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-light .tabs.is-boxed a:hover, .hero.is-light .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-light .tabs.is-boxed li.is-active a, .hero.is-light .tabs.is-boxed li.is-active a:hover, .hero.is-light .tabs.is-toggle li.is-active a, .hero.is-light .tabs.is-toggle li.is-active a:hover {
  color: #f5f5f5;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-light.is-bold {
  background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-light.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #dfd8d9 0%, #f5f5f5 71%, #fff 100%);
  }
}

.hero.is-dark {
  color: #fff;
  background-color: #363636;
}

.hero.is-dark a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-dark strong {
  color: inherit;
}

.hero.is-dark .title {
  color: #fff;
}

.hero.is-dark .subtitle {
  color: #ffffffe6;
}

.hero.is-dark .subtitle a:not(.button), .hero.is-dark .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-dark .navbar-menu {
    background-color: #363636;
  }
}

.hero.is-dark .navbar-item, .hero.is-dark .navbar-link {
  color: #ffffffb3;
}

.hero.is-dark a.navbar-item:hover, .hero.is-dark a.navbar-item.is-active, .hero.is-dark .navbar-link:hover, .hero.is-dark .navbar-link.is-active {
  color: #fff;
  background-color: #292929;
}

.hero.is-dark .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-dark .tabs a:hover {
  opacity: 1;
}

.hero.is-dark .tabs li.is-active a {
  opacity: 1;
  color: #363636 !important;
}

.hero.is-dark .tabs.is-boxed a, .hero.is-dark .tabs.is-toggle a {
  color: #fff;
}

.hero.is-dark .tabs.is-boxed a:hover, .hero.is-dark .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-dark .tabs.is-boxed li.is-active a, .hero.is-dark .tabs.is-boxed li.is-active a:hover, .hero.is-dark .tabs.is-toggle li.is-active a, .hero.is-dark .tabs.is-toggle li.is-active a:hover {
  color: #363636;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-dark.is-bold {
  background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-dark.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #1f191a 0%, #363636 71%, #46403f 100%);
  }
}

.hero.is-primary {
  color: #fff;
  background-color: #00d1b2;
}

.hero.is-primary a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-primary strong {
  color: inherit;
}

.hero.is-primary .title {
  color: #fff;
}

.hero.is-primary .subtitle {
  color: #ffffffe6;
}

.hero.is-primary .subtitle a:not(.button), .hero.is-primary .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-primary .navbar-menu {
    background-color: #00d1b2;
  }
}

.hero.is-primary .navbar-item, .hero.is-primary .navbar-link {
  color: #ffffffb3;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  color: #fff;
  background-color: #00b89c;
}

.hero.is-primary .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-primary .tabs a:hover {
  opacity: 1;
}

.hero.is-primary .tabs li.is-active a {
  opacity: 1;
  color: #00d1b2 !important;
}

.hero.is-primary .tabs.is-boxed a, .hero.is-primary .tabs.is-toggle a {
  color: #fff;
}

.hero.is-primary .tabs.is-boxed a:hover, .hero.is-primary .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-primary .tabs.is-boxed li.is-active a, .hero.is-primary .tabs.is-boxed li.is-active a:hover, .hero.is-primary .tabs.is-toggle li.is-active a, .hero.is-primary .tabs.is-toggle li.is-active a:hover {
  color: #00d1b2;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-primary.is-bold {
  background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-primary.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #009e6c 0%, #00d1b2 71%, #00e7eb 100%);
  }
}

.hero.is-link {
  color: #fff;
  background-color: #485fc7;
}

.hero.is-link a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-link strong {
  color: inherit;
}

.hero.is-link .title {
  color: #fff;
}

.hero.is-link .subtitle {
  color: #ffffffe6;
}

.hero.is-link .subtitle a:not(.button), .hero.is-link .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-link .navbar-menu {
    background-color: #485fc7;
  }
}

.hero.is-link .navbar-item, .hero.is-link .navbar-link {
  color: #ffffffb3;
}

.hero.is-link a.navbar-item:hover, .hero.is-link a.navbar-item.is-active, .hero.is-link .navbar-link:hover, .hero.is-link .navbar-link.is-active {
  color: #fff;
  background-color: #3a51bb;
}

.hero.is-link .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-link .tabs a:hover {
  opacity: 1;
}

.hero.is-link .tabs li.is-active a {
  opacity: 1;
  color: #485fc7 !important;
}

.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-toggle a {
  color: #fff;
}

.hero.is-link .tabs.is-boxed a:hover, .hero.is-link .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-link .tabs.is-boxed li.is-active a, .hero.is-link .tabs.is-boxed li.is-active a:hover, .hero.is-link .tabs.is-toggle li.is-active a, .hero.is-link .tabs.is-toggle li.is-active a:hover {
  color: #485fc7;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-link.is-bold {
  background-image: linear-gradient(141deg, #2959b3 0%, #485fc7 71%, #5658d2 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-link.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #2959b3 0%, #485fc7 71%, #5658d2 100%);
  }
}

.hero.is-info {
  color: #fff;
  background-color: #3e8ed0;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-info strong {
  color: inherit;
}

.hero.is-info .title {
  color: #fff;
}

.hero.is-info .subtitle {
  color: #ffffffe6;
}

.hero.is-info .subtitle a:not(.button), .hero.is-info .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: #3e8ed0;
  }
}

.hero.is-info .navbar-item, .hero.is-info .navbar-link {
  color: #ffffffb3;
}

.hero.is-info a.navbar-item:hover, .hero.is-info a.navbar-item.is-active, .hero.is-info .navbar-link:hover, .hero.is-info .navbar-link.is-active {
  color: #fff;
  background-color: #3082c5;
}

.hero.is-info .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-info .tabs a:hover {
  opacity: 1;
}

.hero.is-info .tabs li.is-active a {
  opacity: 1;
  color: #3e8ed0 !important;
}

.hero.is-info .tabs.is-boxed a, .hero.is-info .tabs.is-toggle a {
  color: #fff;
}

.hero.is-info .tabs.is-boxed a:hover, .hero.is-info .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-info .tabs.is-boxed li.is-active a, .hero.is-info .tabs.is-boxed li.is-active a:hover, .hero.is-info .tabs.is-toggle li.is-active a, .hero.is-info .tabs.is-toggle li.is-active a:hover {
  color: #3e8ed0;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-info.is-bold {
  background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-info.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #208fbc 0%, #3e8ed0 71%, #4d83db 100%);
  }
}

.hero.is-success {
  color: #fff;
  background-color: #48c78e;
}

.hero.is-success a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-success strong {
  color: inherit;
}

.hero.is-success .title {
  color: #fff;
}

.hero.is-success .subtitle {
  color: #ffffffe6;
}

.hero.is-success .subtitle a:not(.button), .hero.is-success .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-success .navbar-menu {
    background-color: #48c78e;
  }
}

.hero.is-success .navbar-item, .hero.is-success .navbar-link {
  color: #ffffffb3;
}

.hero.is-success a.navbar-item:hover, .hero.is-success a.navbar-item.is-active, .hero.is-success .navbar-link:hover, .hero.is-success .navbar-link.is-active {
  color: #fff;
  background-color: #3abb81;
}

.hero.is-success .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-success .tabs a:hover {
  opacity: 1;
}

.hero.is-success .tabs li.is-active a {
  opacity: 1;
  color: #48c78e !important;
}

.hero.is-success .tabs.is-boxed a, .hero.is-success .tabs.is-toggle a {
  color: #fff;
}

.hero.is-success .tabs.is-boxed a:hover, .hero.is-success .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-success .tabs.is-boxed li.is-active a, .hero.is-success .tabs.is-boxed li.is-active a:hover, .hero.is-success .tabs.is-toggle li.is-active a, .hero.is-success .tabs.is-toggle li.is-active a:hover {
  color: #48c78e;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-success.is-bold {
  background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-success.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #29b35e 0%, #48c78e 71%, #56d2af 100%);
  }
}

.hero.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
}

.hero.is-warning a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-warning strong {
  color: inherit;
}

.hero.is-warning .title {
  color: #000000b3;
}

.hero.is-warning .subtitle {
  color: #000000e6;
}

.hero.is-warning .subtitle a:not(.button), .hero.is-warning .subtitle strong {
  color: #000000b3;
}

@media screen and (max-width: 1023px) {
  .hero.is-warning .navbar-menu {
    background-color: #ffe08a;
  }
}

.hero.is-warning .navbar-item, .hero.is-warning .navbar-link {
  color: #000000b3;
}

.hero.is-warning a.navbar-item:hover, .hero.is-warning a.navbar-item.is-active, .hero.is-warning .navbar-link:hover, .hero.is-warning .navbar-link.is-active {
  color: #000000b3;
  background-color: #ffd970;
}

.hero.is-warning .tabs a {
  color: #000000b3;
  opacity: .9;
}

.hero.is-warning .tabs a:hover {
  opacity: 1;
}

.hero.is-warning .tabs li.is-active a {
  opacity: 1;
  color: #ffe08a !important;
}

.hero.is-warning .tabs.is-boxed a, .hero.is-warning .tabs.is-toggle a {
  color: #000000b3;
}

.hero.is-warning .tabs.is-boxed a:hover, .hero.is-warning .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-warning .tabs.is-boxed li.is-active a, .hero.is-warning .tabs.is-boxed li.is-active a:hover, .hero.is-warning .tabs.is-toggle li.is-active a, .hero.is-warning .tabs.is-toggle li.is-active a:hover {
  color: #ffe08a;
  background-color: #000000b3;
  border-color: #000000b3;
}

.hero.is-warning.is-bold {
  background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-warning.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #ffb657 0%, #ffe08a 71%, #fff6a3 100%);
  }
}

.hero.is-danger {
  color: #fff;
  background-color: #f14668;
}

.hero.is-danger a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current), .hero.is-danger strong {
  color: inherit;
}

.hero.is-danger .title {
  color: #fff;
}

.hero.is-danger .subtitle {
  color: #ffffffe6;
}

.hero.is-danger .subtitle a:not(.button), .hero.is-danger .subtitle strong {
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .hero.is-danger .navbar-menu {
    background-color: #f14668;
  }
}

.hero.is-danger .navbar-item, .hero.is-danger .navbar-link {
  color: #ffffffb3;
}

.hero.is-danger a.navbar-item:hover, .hero.is-danger a.navbar-item.is-active, .hero.is-danger .navbar-link:hover, .hero.is-danger .navbar-link.is-active {
  color: #fff;
  background-color: #ef2e55;
}

.hero.is-danger .tabs a {
  color: #fff;
  opacity: .9;
}

.hero.is-danger .tabs a:hover {
  opacity: 1;
}

.hero.is-danger .tabs li.is-active a {
  opacity: 1;
  color: #f14668 !important;
}

.hero.is-danger .tabs.is-boxed a, .hero.is-danger .tabs.is-toggle a {
  color: #fff;
}

.hero.is-danger .tabs.is-boxed a:hover, .hero.is-danger .tabs.is-toggle a:hover {
  background-color: #0a0a0a1a;
}

.hero.is-danger .tabs.is-boxed li.is-active a, .hero.is-danger .tabs.is-boxed li.is-active a:hover, .hero.is-danger .tabs.is-toggle li.is-active a, .hero.is-danger .tabs.is-toggle li.is-active a:hover {
  color: #f14668;
  background-color: #fff;
  border-color: #fff;
}

.hero.is-danger.is-bold {
  background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
}

@media screen and (max-width: 768px) {
  .hero.is-danger.is-bold .navbar-menu {
    background-image: linear-gradient(141deg, #fa0a62 0%, #f14668 71%, #f7595f 100%);
  }
}

.hero.is-small .hero-body {
  padding: 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 4.5rem;
  }

  .hero.is-large .hero-body {
    padding: 18rem 6rem;
  }
}

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container, .hero.is-fullheight .hero-body > .container, .hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.hero.is-halfheight {
  min-height: 50vh;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.hero-video {
  overflow: hidden;
}

.hero-video video {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.hero-video.is-transparent {
  opacity: .3;
}

@media screen and (max-width: 768px) {
  .hero-video {
    display: none;
  }
}

.hero-buttons {
  margin-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .hero-buttons .button {
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-bottom: .75rem;
  }
}

@media screen and (min-width: 769px), print {
  .hero-buttons {
    justify-content: center;
    display: flex;
  }

  .hero-buttons .button:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.hero-head, .hero-foot {
  flex-grow: 0;
  flex-shrink: 0;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 769px), print {
  .hero-body {
    padding: 3rem;
  }
}

.section {
  padding: 3rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 3rem;
  }

  .section.is-medium {
    padding: 9rem 4.5rem;
  }

  .section.is-large {
    padding: 18rem 6rem;
  }
}

.textarea, .input {
  color: #363636;
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
}

.textarea::-webkit-input-placeholder, .input::-webkit-input-placeholder {
  color: #3636364d;
}

.textarea::-moz-placeholder, .input::-moz-placeholder {
  color: #3636364d;
}

.textarea:-moz-placeholder, .input:-moz-placeholder {
  color: #3636364d;
}

.textarea:-ms-input-placeholder, .input:-ms-input-placeholder {
  color: #3636364d;
}

.textarea:hover, .input:hover, .is-hovered.textarea, .is-hovered.input {
  border-color: #b5b5b5;
}

.textarea:focus, .input:focus, .is-focused.textarea, .is-focused.input, .textarea:active, .input:active, .is-active.textarea, .is-active.input {
  border-color: #485fc7;
  box-shadow: 0 0 0 .125em #485fc740;
}

[disabled].textarea, [disabled].input, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7a7a7a;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

[disabled].textarea::-webkit-input-placeholder, [disabled].input::-webkit-input-placeholder, fieldset[disabled] .textarea::-webkit-input-placeholder, fieldset[disabled] .input::-webkit-input-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea::-moz-placeholder, [disabled].input::-moz-placeholder, fieldset[disabled] .textarea::-moz-placeholder, fieldset[disabled] .input::-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-moz-placeholder, [disabled].input:-moz-placeholder, fieldset[disabled] .textarea:-moz-placeholder, fieldset[disabled] .input:-moz-placeholder {
  color: #7a7a7a4d;
}

[disabled].textarea:-ms-input-placeholder, [disabled].input:-ms-input-placeholder, fieldset[disabled] .textarea:-ms-input-placeholder, fieldset[disabled] .input:-ms-input-placeholder {
  color: #7a7a7a4d;
}

.label {
  color: #363636;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: .75rem;
}

.label.is-medium {
  font-size: 1.25rem;
}

.label.is-large {
  font-size: 1.5rem;
}

.help {
  margin-top: .25rem;
  font-size: .75rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #0a0a0a;
}

.help.is-light {
  color: #f5f5f5;
}

.help.is-dark {
  color: #363636;
}

.help.is-primary {
  color: #00d1b2;
}

.help.is-link {
  color: #485fc7;
}

.help.is-info {
  color: #3e8ed0;
}

.help.is-success {
  color: #48c78e;
}

.help.is-warning {
  color: #ffe08a;
}

.help.is-danger {
  color: #f14668;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (min-width: 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: .75rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 1.25rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 1.5rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: inherit;
  font-size: 1rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #4a4a4a;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: .75rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 1.25rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 1.5rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #dbdbdb;
  height: 2.5em;
  pointer-events: none;
  width: 2.5em;
  z-index: 4;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.5em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.5em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: .75rem;
}

.control.is-loading.is-medium:after {
  font-size: 1.25rem;
}

.control.is-loading.is-large:after {
  font-size: 1.5rem;
}

.textarea, .input {
  max-width: 100%;
  width: 100%;
  box-shadow: inset 0 .0625em .125em #0a0a0a0d;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 0 0 .125em #ffffff40;
}

.is-black.textarea, .is-black.input {
  border-color: #0a0a0a;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 0 0 .125em #0a0a0a40;
}

.is-light.textarea, .is-light.input {
  border-color: #f5f5f5;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 0 0 .125em #f5f5f540;
}

.is-dark.textarea, .is-dark.input {
  border-color: #363636;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 0 0 .125em #36363640;
}

.is-primary.textarea, .is-primary.input {
  border-color: #00d1b2;
}

.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 0 0 .125em #00d1b240;
}

.is-link.textarea, .is-link.input {
  border-color: #485fc7;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 0 0 .125em #485fc740;
}

.is-info.textarea, .is-info.input {
  border-color: #3e8ed0;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 0 0 .125em #3e8ed040;
}

.is-success.textarea, .is-success.input {
  border-color: #48c78e;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 0 0 .125em #48c78e40;
}

.is-warning.textarea, .is-warning.input {
  border-color: #ffe08a;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 0 0 .125em #ffe08a40;
}

.is-danger.textarea, .is-danger.input {
  border-color: #f14668;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 0 0 .125em #f1466840;
}

.is-small.textarea, .is-small.input {
  border-radius: 2px;
  font-size: .75rem;
}

.is-medium.textarea, .is-medium.input {
  font-size: 1.25rem;
}

.is-large.textarea, .is-large.input {
  font-size: 1.5rem;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(1.125em - 1px);
  padding-right: calc(1.125em - 1px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  max-width: 100%;
  min-width: 100%;
  resize: vertical;
  padding: calc(.75em - 1px);
  display: block;
}

.textarea:not([rows]) {
  max-height: 40em;
  min-height: 8em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.container {
  width: auto;
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 1215px) {
  .container.is-widescreen:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (max-width: 1407px) {
  .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

@media screen and (min-width: 1216px) {
  .container:not(.is-max-desktop) {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1344px;
  }
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon.is-small {
  height: 1rem;
  width: 1rem;
}

.icon.is-medium {
  height: 2rem;
  width: 2rem;
}

.icon.is-large {
  height: 3rem;
  width: 3rem;
}

.icon-text {
  color: inherit;
  vertical-align: top;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.5rem;
  display: inline-flex;
}

.icon-text .icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.icon-text .icon:not(:last-child) {
  margin-right: .25em;
}

.icon-text .icon:not(:first-child) {
  margin-left: .25em;
}

div.icon-text {
  display: flex;
}

.title, .subtitle {
  word-break: break-word;
}

.title em, .title span, .subtitle em, .subtitle span {
  font-weight: inherit;
}

.title sub, .subtitle sub, .title sup, .subtitle sup {
  font-size: .75em;
}

.title .tag, .subtitle .tag {
  vertical-align: middle;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

.title strong {
  color: inherit;
  font-weight: inherit;
}

.title:not(.is-spaced) + .subtitle {
  margin-top: -1.25rem;
}

.title.is-1 {
  font-size: 3rem;
}

.title.is-2 {
  font-size: 2.5rem;
}

.title.is-3 {
  font-size: 2rem;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-5 {
  font-size: 1.25rem;
}

.title.is-6 {
  font-size: 1rem;
}

.title.is-7 {
  font-size: .75rem;
}

.subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
  font-weight: 600;
}

.subtitle:not(.is-spaced) + .title {
  margin-top: -1.25rem;
}

.subtitle.is-1 {
  font-size: 3rem;
}

.subtitle.is-2 {
  font-size: 2.5rem;
}

.subtitle.is-3 {
  font-size: 2rem;
}

.subtitle.is-4 {
  font-size: 1.5rem;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle.is-6 {
  font-size: 1rem;
}

.subtitle.is-7 {
  font-size: .75rem;
}

.table {
  color: #363636;
  background-color: #fff;
}

.table td, .table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.table td.is-white, .table th.is-white {
  color: #0a0a0a;
  background-color: #fff;
  border-color: #fff;
}

.table td.is-black, .table th.is-black {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.table td.is-light, .table th.is-light {
  color: #000000b3;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.table td.is-dark, .table th.is-dark {
  color: #fff;
  background-color: #363636;
  border-color: #363636;
}

.table td.is-primary, .table th.is-primary {
  color: #fff;
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.table td.is-link, .table th.is-link {
  color: #fff;
  background-color: #485fc7;
  border-color: #485fc7;
}

.table td.is-info, .table th.is-info {
  color: #fff;
  background-color: #3e8ed0;
  border-color: #3e8ed0;
}

.table td.is-success, .table th.is-success {
  color: #fff;
  background-color: #48c78e;
  border-color: #48c78e;
}

.table td.is-warning, .table th.is-warning {
  color: #000000b3;
  background-color: #ffe08a;
  border-color: #ffe08a;
}

.table td.is-danger, .table th.is-danger {
  color: #fff;
  background-color: #f14668;
  border-color: #f14668;
}

.table td.is-narrow, .table th.is-narrow {
  white-space: nowrap;
  width: 1%;
}

.table td.is-selected, .table th.is-selected {
  color: #fff;
  background-color: #00d1b2;
}

.table td.is-selected a, .table td.is-selected strong, .table th.is-selected a, .table th.is-selected strong {
  color: currentColor;
}

.table td.is-vcentered, .table th.is-vcentered {
  vertical-align: middle;
}

.table th {
  color: #363636;
}

.table th:not([align]) {
  text-align: left;
}

.table tr.is-selected {
  color: #fff;
  background-color: #00d1b2;
}

.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor;
}

.table tr.is-selected td, .table tr.is-selected th {
  color: currentColor;
  border-color: #fff;
}

.table thead {
  background-color: #0000;
}

.table thead td, .table thead th {
  color: #363636;
  border-width: 0 0 2px;
}

.table tfoot {
  background-color: #0000;
}

.table tfoot td, .table tfoot th {
  color: #363636;
  border-width: 2px 0 0;
}

.table tbody {
  background-color: #0000;
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
  border-bottom-width: 0;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 1px;
}

.table.is-bordered tr:last-child td, .table.is-bordered tr:last-child th {
  border-bottom-width: 1px;
}

.table.is-fullwidth {
  width: 100%;
}

.table.is-hoverable tbody tr:not(.is-selected):hover, .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
  background-color: #fafafa;
}

.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(2n) {
  background-color: #f5f5f5;
}

.table.is-narrow td, .table.is-narrow th {
  padding: .25em .5em;
}

.table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
  background-color: #fafafa;
}

.table-container {
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.table-wrapper .table {
  margin-bottom: 0;
}

.table-wrapper:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 1023px) {
  .table-wrapper {
    overflow-x: auto;
  }
}

.b-table {
  transition: opacity 86ms ease-out;
}

@media screen and (min-width: 769px), print {
  .b-table .table-mobile-sort {
    display: none;
  }
}

.b-table .icon {
  transition: transform .15s ease-out, opacity 86ms ease-out;
}

.b-table .icon.is-desc {
  transform: rotate(180deg);
}

.b-table .icon.is-expanded {
  transform: rotate(90deg);
}

.b-table .table {
  width: 100%;
  border-collapse: separate;
  border: 1px solid #0000;
  border-radius: 4px;
}

.b-table .table th {
  font-weight: 600;
}

.b-table .table th .th-wrap {
  align-items: center;
  display: flex;
}

.b-table .table th .th-wrap .icon {
  margin-left: .5rem;
  margin-right: 0;
  font-size: 1rem;
}

.b-table .table th .th-wrap.is-numeric {
  text-align: right;
  flex-direction: row-reverse;
}

.b-table .table th .th-wrap.is-numeric .icon {
  margin-left: 0;
  margin-right: .5rem;
}

.b-table .table th .th-wrap.is-centered {
  text-align: center;
  justify-content: center;
}

.b-table .table th.is-current-sort {
  border-color: #7a7a7a;
  font-weight: 700;
}

.b-table .table th.is-sortable:hover {
  border-color: #7a7a7a;
}

.b-table .table th.is-sortable, .b-table .table th.is-sortable .th-wrap {
  cursor: pointer;
}

.b-table .table th .multi-sort-cancel-icon {
  margin-left: 10px;
}

.b-table .table th.is-sticky {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 3 !important;
}

.b-table .table tr.is-selected .checkbox input:checked + .check {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:hsl(171deg, 100%25, 41%25)' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") center no-repeat;
}

.b-table .table tr.is-selected .checkbox input + .check {
  border-color: #fff;
}

.b-table .table tr.is-empty:hover {
  background-color: #0000;
}

.b-table .table .is-chevron-cell, .b-table .table .is-checkbox-cell {
  width: 40px;
}

.b-table .table .is-chevron-cell, .b-table .table .is-checkbox-cell .checkbox {
  vertical-align: middle;
}

.b-table .table .is-checkbox-cell .checkbox.b-checkbox {
  margin-right: 0;
}

.b-table .table .is-checkbox-cell .checkbox .check {
  transition: none;
}

.b-table .table tr.detail {
  background: #fafafa;
  box-shadow: inset 0 1px 3px #dbdbdb;
}

.b-table .table tr.detail .detail-container {
  padding: 1rem;
}

.b-table .table:focus {
  border-color: #485fc7;
  box-shadow: 0 0 0 .125em #485fc740;
}

.b-table .table.is-bordered th.is-current-sort, .b-table .table.is-bordered th.is-sortable:hover {
  background: #f5f5f5;
  border-color: #dbdbdb;
}

.b-table .table td.is-sticky {
  z-index: 1;
  background: #fff;
  position: sticky;
  left: 0;
}

.b-table .table td.is-image-cell .image {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
}

.b-table .table td.is-progress-cell {
  min-width: 5rem;
  vertical-align: middle;
}

.b-table .table-wrapper.has-sticky-header {
  height: 300px;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .b-table .table-wrapper.has-sticky-header.has-mobile-cards {
    height: initial !important;
    overflow-y: initial !important;
  }
}

.b-table .table-wrapper.has-sticky-header tr:first-child th {
  z-index: 2;
  background: #fff;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 768px) {
  .b-table .table-wrapper.has-mobile-cards thead {
    display: none;
  }

  .b-table .table-wrapper.has-mobile-cards tfoot th {
    display: inherit;
    border: 0;
  }

  .b-table .table-wrapper.has-mobile-cards tr {
    max-width: 100%;
    display: block;
    position: relative;
    box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
  }

  .b-table .table-wrapper.has-mobile-cards tr td {
    display: inherit;
    border: 0;
  }

  .b-table .table-wrapper.has-mobile-cards tr td:last-child {
    border-bottom: 0;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(:last-child) {
    margin-bottom: 1rem;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]) {
    background: inherit;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]):hover {
    background-color: inherit;
  }

  .b-table .table-wrapper.has-mobile-cards tr.detail {
    margin-top: -1rem;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td {
    width: auto;
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
    justify-content: space-between;
    display: flex;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
    content: attr(data-label);
    text-align: left;
    padding-right: .5rem;
    font-weight: 600;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-image-cell .image {
    width: 6rem;
    height: 6rem;
    margin: 0 auto .5rem;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-progress-cell span, .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-progress-cell progress {
    width: 45%;
    align-self: center;
    align-items: center;
    display: flex;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-checkbox-cell, .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-image-cell {
    border-bottom: 0 !important;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-checkbox-cell:before, .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-actions-cell:before, .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-label-hidden:before, .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-image-cell:before {
    display: none;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-label-hidden span {
    width: 100%;
    display: block;
  }

  .b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td.is-label-hidden.is-progress-col progress {
    width: 100%;
  }
}

.b-table .table-wrapper.is-card-list thead {
  display: none;
}

.b-table .table-wrapper.is-card-list tfoot th {
  display: inherit;
  border: 0;
}

.b-table .table-wrapper.is-card-list tr {
  max-width: 100%;
  display: block;
  position: relative;
  box-shadow: 0 2px 3px #0a0a0a1a, 0 0 0 1px #0a0a0a1a;
}

.b-table .table-wrapper.is-card-list tr td {
  display: inherit;
  border: 0;
}

.b-table .table-wrapper.is-card-list tr td:last-child {
  border-bottom: 0;
}

.b-table .table-wrapper.is-card-list tr:not(:last-child) {
  margin-bottom: 1rem;
}

.b-table .table-wrapper.is-card-list tr:not([class*="is-"]) {
  background: inherit;
}

.b-table .table-wrapper.is-card-list tr:not([class*="is-"]):hover {
  background-color: inherit;
}

.b-table .table-wrapper.is-card-list tr.detail {
  margin-top: -1rem;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td {
  width: auto;
  text-align: right;
  border-bottom: 1px solid #f5f5f5;
  justify-content: space-between;
  display: flex;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
  content: attr(data-label);
  text-align: left;
  padding-right: .5rem;
  font-weight: 600;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-image-cell .image {
  width: 6rem;
  height: 6rem;
  margin: 0 auto .5rem;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-progress-cell span, .b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-progress-cell progress {
  width: 45%;
  align-self: center;
  align-items: center;
  display: flex;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-checkbox-cell, .b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-image-cell {
  border-bottom: 0 !important;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-checkbox-cell:before, .b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-actions-cell:before, .b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-label-hidden:before, .b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-image-cell:before {
  display: none;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-label-hidden span {
  width: 100%;
  display: block;
}

.b-table .table-wrapper.is-card-list tr:not(.detail):not(.is-empty):not(.table-footer) td.is-label-hidden.is-progress-col progress {
  width: 100%;
}

.b-table.is-loading {
  pointer-events: none;
  opacity: .5;
  position: relative;
}

.b-table.is-loading:after {
  content: "";
  height: 1em;
  width: 1em;
  width: 5em;
  height: 5em;
  border: .25em solid #0000;
  border-color: #0000 #0000 #dbdbdb #dbdbdb;
  border-radius: 9999px;
  animation: .5s linear infinite spinAround;
  display: block;
  position: absolute;
  top: 4em;
  left: calc(50% - 2.5em);
}

.b-table.has-pagination .table-wrapper {
  margin-bottom: 0;
}

.b-table.has-pagination .table-wrapper + .notification {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

label {
  margin-right: 1em;
}

table tr td:nth-of-type(1) {
  font-style: italic;
  font-weight: bold;
}

table tr td:nth-of-type(2) {
  font-style: italic;
}

table tr td:nth-of-type(3), table tr td:nth-of-type(4) {
  font-weight: bold;
}

/*# sourceMappingURL=index.6af74dc7.css.map */
