@charset "utf-8";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/helpers/_all.sass";
@import "bulma/sass/layout/hero.sass";
@import "bulma/sass/layout/section.sass";
@import "bulma/sass/form/shared.sass";
@import "bulma/sass/form/tools.sass";
@import "bulma/sass/form/input-textarea.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/elements/icon.sass";
@import "bulma/sass/elements/title.sass";
@import "bulma/sass/elements/table.sass";
@import "node_modules/bulma-responsive-tables/bulma-responsive-tables";

label {
  margin-right: 1em;
}

table {
  tr {
    td:nth-of-type(1) {
      font-style: italic;
      font-weight: bold; 
    }

    td:nth-of-type(2) {
      font-style: italic;
    }

    td:nth-of-type(3) {
      font-weight: bold;
    }

    td:nth-of-type(4) {
      font-weight: bold;
    }
    
  }
}
